/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useRef, useCallback} from '@wordpress/element';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {useSelector} from 'react-redux';
import {Viewer} from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers.js';
import ActionPanel from './ActionPanel';
// import Branding from './Branding';
// import Compass from './Compass';
import Caption from './Caption';
import Pops from './Pops';
import Floorplan from './Floorplan';
import {useAppDispatch} from '../../store';
import {
	selectCurrentMarkerView,
	selectDefaultScene,
	selectCurrent,
	// selectCompass,
	selectMiniPlan,
	selectClicked,
	updateClicked,
	updateCompassDegree,
	updateCurrentMarkerView,
	fetchPanorama,
} from '../../store/panoramaSlice';
import settings from './settings';
import {checkLatLong, getAngle, getViewAngle} from './functions';
import {markerMaker, updateMarkers} from './Marker';

// Import styles
import SCSS from './Player.module.scss';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';
const ScreenSizeDetector = require('screen-size-detector');

// const animationSpeed = '-8rpm';
// const animationSpeed = '-16rpm';

type viewerType = typeof Viewer;

const Player = () => {
	const [currentViewer, setCurrentViewer] = useState(null);
	const [currentMarkersPlugin, setCurrentMarkersPlugin] = useState(null);
	const [fullscreenStatus, setFullscreenStatus] = useState(false);
	// const [containerWidth, setContainerWidth] = useState(0);
	const PlayerContainer = useRef(null);
	const dispatch = useAppDispatch();
	const defaultScene = useSelector(selectDefaultScene);
	const currentMarkerView = useSelector(selectCurrentMarkerView);
	const clicked = useSelector(selectClicked);
	const current = useSelector(selectCurrent);
	// const compass = useSelector(selectCompass);
	const miniplan = useSelector(selectMiniPlan);
	const fullscreenHandle = useFullScreenHandle();
	const screen = new ScreenSizeDetector();

	const enterFullscreen = () => {
		fullscreenHandle.enter();
	};
	const exitFullscreen = () => {
		fullscreenHandle.exit();
	};
	const reportFullscreenChange = useCallback(
		(state, handle) => {
			if (handle === fullscreenHandle) {
				setFullscreenStatus(state);
			}
		},
		[fullscreenHandle]
	);

	/**********************************
	 * Initialization panorama viewer
	 * Load first rendering
	 **********************************/
	useEffect(() => {
		/***********************************
		 * Initialization panorama viewer
		 **********************************/
		const init = () => {
			const markers = markerMaker(defaultScene.markers);
			const viewer: viewerType = new Viewer({
				container: PlayerContainer.current,
				panorama: defaultScene.images,
				defaultLong: defaultScene.default.longitude,
				defaultLat: defaultScene.default.latitude,
				plugins: [[MarkersPlugin, markers]],
				sphereCorrection: {
					pan: defaultScene.pan,
				},
				...settings,
			});

			/************************************
			 * Click on the sphereial viewer
			 ***********************************/
			viewer.on('click', (event: any, position: any) => {
				checkLatLong(position, false);
			});
			/************************************
			 * Spherical viewer's position updated
			 ***********************************/
			viewer.on('position-updated', (event: any, position: any) => {
				checkLatLong(position, false);
				/*************************************
				 * Update spherical viewer degree
				 ************************************/
				const degree = getAngle(position.longitude);
				const viewDegree = getViewAngle(position.longitude);
				// const viewAngle = utils.parseAngle(viewDegree);
				// console.log('viewAngle =', viewAngle);
				dispatch(updateCompassDegree(degree, viewDegree));
			});
			/**************************************
			 * Store the spherical viewer into
			 * component's state CurrentViewer
			 *************************************/
			setCurrentViewer(viewer);
			/***************************************
			 * Marker Event
			 * select-marker(event): change panorama
			 * image and update all markers
			 **************************************/
			// Get MarkersPlugin
			const markersPlugin = viewer.getPlugin(MarkersPlugin);
			// Set MarkersPlugin to state CurrentMarkersPlugin
			setCurrentMarkersPlugin(markersPlugin);
			markersPlugin.clearMarkers();
			// Callback of Clicked Marker
			markersPlugin.on('select-marker', (event: any, marker: any) => {
				// console.log('mark=', marker);
				dispatch(updateClicked('marker'));
				dispatch(
					updateCurrentMarkerView(marker.data.latitude, marker.data.longitude)
				);
				const destinationScene = marker.config.tooltip.content;
				markersPlugin.clearMarkers();
				// Switch panorama view
				dispatch(fetchPanorama(destinationScene));
			});
		};
		init();
	}, [dispatch, setCurrentViewer, defaultScene]);

	/****************************************
	 * Load Scene after updating current scene
	 ***************************************/
	useEffect(() => {
		// Get image according to the scheme
		const image = current.images;
		/***************************************
		 * If currentViwer is existing
		 * re-render the viewer with new rendering
		 * Before set panorama image rotate
		 * the viewer to the right position
		 * this select marker provided
		 **************************************/
		if (currentViewer) {
			// console.log('currentMarkerView=', currentMarkerView);
			const cv = currentViewer as viewerType;

			cv.rotate({
				longitude:
					clicked === 'menu' || clicked === 'miniplan'
						? current.default.longitude
						: currentMarkerView.longitude,
				latitude:
					clicked === 'menu' || clicked === 'miniplan'
						? current.default.latitude
						: currentMarkerView.latitude,
			});
			// cv.animate({
			//   longitude: clicked ==='menu' ? current.default.longitude : currentMarkerView.longitude,
			//   latitude: clicked ==='menu' ? current.default.latitude : currentMarkerView.latitude,
			//   speed: animationSpeed,
			// });
			/****************************************
			 * Load new scene's image
			 ***************************************/
			cv.setPanorama(image, {
				sphereCorrection: {
					pan: current.pan,
				},
			}).then(() => {
				updateMarkers(current.markers, currentMarkersPlugin);
			});
		}
	}, [current]);

	return (
		<FullScreen handle={fullscreenHandle} onChange={reportFullscreenChange}>
			<div className={SCSS.Player} ref={PlayerContainer} />
			{/* <Branding /> z-inde: 100 */}
			{/* <Compass degree={compass.degree} /> z-inde: 200 */}
			<ActionPanel
				view={currentViewer}
				enterFullscreen={enterFullscreen}
				exitFullscreen={exitFullscreen}
				fullscreenStatus={fullscreenStatus}
			/>
			{/* z-inde: 300 */}
			<Caption name={current.name} />
			{/* z-inde: 400 */}
			{miniplan && screen.width > 860 && <Floorplan />}
			{/* <Floorplan /> z-inde: 500 */}
			<Pops />
			{/* z-inde: 600 */}
		</FullScreen>
	);
};

export default Player;
