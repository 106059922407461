import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Bedroom: IScene = {
	name: 'Bedroom',
	pan: '-90deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 124,
		y: 365,
	},
	images: Renderings.__bedroom__,
	default: {
		latitude: -0.054740279337247344,
		longitude: 2.06667747136948,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.9194426589064486,
				longitude: 4.142061790024028,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.061868478008881844,
					longitude: 4.011213426195084,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3634220567382094,
				longitude: 5.90902183698295,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.0023499391129031544,
					longitude: 5.880041393709659,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.13633016323442737,
				longitude: 6.002476104743038,
			},
			target: {
				name: 'Terrace',
				view: {
					latitude: -0.040875803536653876,
					longitude: 0.012735184599223126,
				},
			},
		},
	],
};

export default __Bedroom;
