import {useEffect} from '@wordpress/element';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import Player from './Player';
import SCSS from './App.module.scss';
import Renderings from '../renderings';

const _ = require('lodash');

const theme = createTheme({
	palette: {
		primary: {
			main: grey[50],
		},
	},
});

function App() {
	useEffect(() => {
		_.forEach(Renderings, (value: string) => {
			// console.log('Rendering Value =', value);
			const img = new Image();
			img.src = value;
		});
	}, []);
	return (
		<ThemeProvider theme={theme}>
			<div className={SCSS.App}>
				<Player />
			</div>
		</ThemeProvider>
	);
}

export default App;
