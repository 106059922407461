import {useState} from '@wordpress/element';
import {useDispatch, useSelector} from 'react-redux';
import {Viewer} from 'photo-sphere-viewer';
import {makeStyles} from '@mui/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import AdjustIcon from '@mui/icons-material/Adjust';
import ListIcon from '@mui/icons-material/List';
import Tooltip from '@mui/material/Tooltip';
import SCSS from './ActionPanel.module.scss';
import {
	openPOP,
	selectPop,
	selectMiniPlan,
	toggleMiniPlan,
} from '../../../store/panoramaSlice';
const ScreenSizeDetector = require('screen-size-detector');

type typeViewer = typeof Viewer;

type Props = {
	view: typeViewer;
	enterFullscreen: () => void;
	exitFullscreen: () => void;
	fullscreenStatus: boolean;
};

const useStyles = makeStyles({
	navigationRoot: {
		backgroundColor: '#ffffff33 !important',
		backdropFilter: 'blur(6px)',
	},
	buttonRoot: {
		padding: 'initial !important',
	},
	tooltip: {
		fontSize: '18px !important',
		letterSpacing: '0.02rem !important',
		backgroundColor: '#151515AA !important',
		backdropFilter: 'blur(5px)',
		color: '#FFFFFF !important',
	},
	arrow: {
		color: '#151515AA !important',
	},
});

const ActionPanel = (props: Props) => {
	const {view, enterFullscreen, exitFullscreen, fullscreenStatus} = props;
	const [rotate, setRotate] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const pop = useSelector(selectPop);
	const miniplan = useSelector(selectMiniPlan);
	const screen = new ScreenSizeDetector();

	/**********************************
	 * Functions
	 *********************************/
	const handleChange = (event: any, value: string) => {
		switch (value) {
			case 'rotate':
				view.toggleAutorotate();
				break;
			case 'enter fullscreen':
				enterFullscreen();
				break;
			case 'exit fullscreen':
				exitFullscreen();
				break;
			case 'selection':
				dispatch(openPOP());
				break;
			case 'floorplan':
				dispatch(toggleMiniPlan());
				break;
		}
	};

	if (view) {
		const cv = view as typeViewer;
		cv.on('autorotate', (event: any, data: any) => {
			setRotate(data);
		});
	}

	return !pop ? (
		<div className={SCSS.ActionPanel}>
			<div className={SCSS.ButtonContainer}>
				<BottomNavigation
					onChange={handleChange}
					showLabels={false}
					classes={{root: classes.navigationRoot}}
				>
					{fullscreenStatus ? (
						<Tooltip
							title='Exit Fullscreen'
							placement='top'
							arrow
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						>
							<BottomNavigationAction
								classes={{root: classes.buttonRoot}}
								label=''
								icon={<FullscreenExitIcon />}
								value='exit fullscreen'
								showLabel={false}
							/>
						</Tooltip>
					) : (
						<Tooltip
							title='Fullscreen'
							placement='top'
							arrow
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						>
							<BottomNavigationAction
								classes={{root: classes.buttonRoot}}
								label=''
								icon={<FullscreenIcon />}
								value='enter fullscreen'
								showLabel={false}
							/>
						</Tooltip>
					)}

					{rotate ? (
						<Tooltip
							title='Stop Rotate'
							placement='top'
							arrow
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						>
							<BottomNavigationAction
								classes={{root: classes.buttonRoot}}
								label=''
								icon={<StopIcon />}
								value='rotate'
								showLabel={false}
							/>
						</Tooltip>
					) : (
						<Tooltip
							title='Start Rotate'
							placement='top'
							arrow
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						>
							<BottomNavigationAction
								classes={{root: classes.buttonRoot}}
								label=''
								icon={<PlayArrowIcon />}
								value='rotate'
								showLabel={false}
							/>
						</Tooltip>
					)}

					<Tooltip
						title='Selection'
						placement='top'
						arrow
						classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
					>
						<BottomNavigationAction
							classes={{root: classes.buttonRoot}}
							label=''
							icon={<ListIcon />}
							value='selection'
							showLabel={false}
						/>
					</Tooltip>

					{screen.width > 860 && (
						<Tooltip
							title={miniplan ? 'Hide Floorplan' : 'Show Floorplan'}
							placement='top'
							arrow
							classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
						>
							<BottomNavigationAction
								classes={{root: classes.buttonRoot}}
								label=''
								icon={<AdjustIcon />}
								value='floorplan'
								showLabel={false}
							/>
						</Tooltip>
					)}
				</BottomNavigation>
			</div>
		</div>
	) : null;
};

export default ActionPanel;
