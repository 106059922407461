import {IMakers} from '../../../scenes/IScene';
import {Circle} from './Circle';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers.js';
type MarkersPluginType = typeof MarkersPlugin;

const createMarker = (marker: IMakers) => {
	const size = 30;
	return {
		id: marker.markerID,
		longitude: marker.markerPosition.longitude,
		latitude: marker.markerPosition.latitude,
		html: Circle(size),
		width: size,
		height: size,
		tooltip: marker.target.name,
		data: marker.target.view,
	};
};

export const markerMaker = (markers: Array<IMakers>) => {
	return {
		markers: markers.map((marker: IMakers) => createMarker(marker)),
	};
};

export const updateMarkers = (
	markers: Array<IMakers>,
	markersPlugin: MarkersPluginType
) => {
	markersPlugin.clearMarkers();
	try {
		markers.map((marker: IMakers) => {
			markersPlugin.addMarker(createMarker(marker));
			return null;
		});
	} catch (error) {
		if (process.env.NODE_ENV === 'development') {
			console.log(error);
		}
	}
};
