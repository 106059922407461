import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Kitchen: IScene = {
	name: 'Kitchen',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 100,
		y: 380,
	},
	images: Renderings.__kitchen__,
	default: {
		latitude: 0.051847800574496006,
		longitude: 6.281287647925217,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.160182954228139,
				longitude: 6.094464008174786,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.05743708338157516,
					longitude: 0.007815773444120114,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3324607243882183,
				longitude: 0.2617975988122035,
			},
			target: {
				name: 'Master Bedroom 1',
				view: {
					latitude: -0.040949183846788806,
					longitude: 0.7042529176922045,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7555931169671015,
				longitude: 0.9452267443868415,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: 0.020527044655005433,
					longitude: 0.4588264465109048,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.12980680000268285,
				longitude: 6.282354220156182,
			},
			target: {
				name: 'Terrace',
				view: {
					latitude: -0.1292850412418387,
					longitude: 0.19939602225172276,
				},
			},
		},
	],
};

export default __Kitchen;
