import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Master_Bedroom_2: IScene = {
	name: 'Master Bedroom 2',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 128,
		y: 209,
	},
	images: Renderings.__master_bedroom_2__,
	default: {
		latitude: -0.19597634357223748,
		longitude: 1.9689850006913705,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5306728120775555,
				longitude: 3.202464862499869,
			},
			target: {
				name: 'Master Bedroom 1',
				view: {
					latitude: -0.045634259293784396,
					longitude: 3.126359154921573,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5045492932085285,
				longitude: 0.44738369618831686,
			},
			target: {
				name: 'Terrace',
				view: {
					latitude: -0.00332005802498081,
					longitude: 0.5695496957869238,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2882941811815716,
				longitude: 3.1700023273982296,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: 0.056992955347380914,
					longitude: 3.1402869157628652,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.37109496635750583,
				longitude: 3.27915297984526,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.009340965359313724,
					longitude: 3.620668915930655,
				},
			},
		},
	],
};

export default __Master_Bedroom_2;
