import IScene from './IScene';

import __Kitchen from './__Kitchen';
import __Living from './__Living';
import __Master_Bedroom_1 from './__Master_Bedroom_1';
import __Master_Bedroom_2 from './__Master_Bedroom_2';
import __Ensuite from './__Ensuite';
import __Bedroom from './__Bedroom';
import __Terrace from './__Terrace';

const Scenes: Array<IScene> = [
	__Kitchen,
	__Living,
	__Terrace,
	__Master_Bedroom_1,
	__Master_Bedroom_2,
	__Bedroom,
	__Ensuite,
];

export default Scenes;
