import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Terrace: IScene = {
	name: 'Terrace',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 109,
		y: 169,
	},
	images: Renderings.__terrace__,
	default: {
		latitude: -0.14840055209701397,
		longitude: 6.03617551663274,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.31642033314989826,
				longitude: 3.8016161197374214,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.0597079908015421,
					longitude: 3.7476146540925077,
				},
			},
		},
	],
};

export default __Terrace;
