import SCSS from './HotSpot.module.scss';
const classnames = require('classnames');

type Props = {
	degree: string;
	name: string;
	// currentName: string;
	x: number;
	y: number;
	active: boolean;
	changeScene: (scene: string) => void;
};

const HotSpot = (props: Props) => {
	const {name, x, y, active, changeScene, degree} = props;
	return (
		<div
			onClick={() => changeScene(name)}
			data-title={name}
			className={classnames([SCSS.Dot, {[`${SCSS.Active}`]: active}])}
			style={{
				left: `${x}px`,
				top: `${y}px`,
				transform: `rotate(${degree})`,
			}}
		/>
	);
};

export default HotSpot;
