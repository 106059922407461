import IImage from './IImage';

import __kitchen__ from './condo/kitchen.jpg';
import __living__ from './condo/living.jpg';
import __master_bedroom_1__ from './condo/master-bedroom-1.jpg';
import __master_bedroom_2__ from './condo/master-bedroom-2.jpg';
import __ensuite__ from './condo/ensuite.jpg';
import __bedroom__ from './condo/bedroom.jpg';
import __terrace__ from './condo/terrace.jpg';

const Renderings: IImage = {
	__kitchen__,
	__living__,
	__master_bedroom_1__,
	__master_bedroom_2__,
	__ensuite__,
	__bedroom__,
	__terrace__,
};

export default Renderings;
