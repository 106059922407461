import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Living: IScene = {
	name: 'Living',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 72,
		y: 287,
	},
	images: Renderings.__living__,
	default: {
		latitude: 0.02990748545520372,
		longitude: 0.02252180625611877,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.18745850406601972,
				longitude: 2.879487853051511,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.12441719780102423,
					longitude: 2.5773823308312007,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5653513678770521,
				longitude: 1.3368931846598382,
			},
			target: {
				name: 'Master Bedroom 1',
				view: {
					latitude: -0.040949183846788806,
					longitude: 0.7042529176922045,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5807478099465682,
				longitude: 1.8714386721093457,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.040949183846788806,
					longitude: 0.7042529176922045,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4388090060298442,
				longitude: 2.589003098359542,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: 0.020527044655005433,
					longitude: 0.4588264465109048,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2800388038682864,
				longitude: 0.19246203985178298,
			},
			target: {
				name: 'Terrace',
				view: {
					latitude: -0.1292850412418387,
					longitude: 0.19939602225172276,
				},
			},
		},
	],
};

export default __Living;
