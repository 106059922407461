interface ISetting {
	defaultZoomLvl: number;
	navbar: [];
	minFov: number;
	maxFov: number;
	fisheye: boolean;
	autorotateDelay: number;
	autorotateSpeed: string;
	mousewheel: boolean;
	mousemove: boolean;
	mousewheelCtrlKey: boolean;
	captureCursor: boolean;
	touchmoveTwoFingers: boolean;
	sphereCorrection?: {
		pan?: number;
		tilt?: number;
		roll?: number;
	};
	moveSpeed: number;
	canvasBackground: string;
	moveInertia: boolean;
	keyboard: {
		[key: string]: string;
	};
}

const settings: ISetting = {
	defaultZoomLvl: 35, // default: 50, from 0 to 100
	navbar: [],
	minFov: 30,
	maxFov: 90,
	fisheye: false,
	autorotateDelay: 0,
	autorotateSpeed: '0.5rpm',
	mousewheel: true,
	mousemove: true,
	mousewheelCtrlKey: false,
	captureCursor: false,
	touchmoveTwoFingers: false,
	moveSpeed: 1,
	canvasBackground: '#f2f2f2',
	moveInertia: true,
	keyboard: {
		ArrowUp: 'rotateLatitudeUp',
		ArrowDown: 'rotateLatitudeDown',
		ArrowRight: 'rotateLongitudeRight',
		ArrowLeft: 'rotateLongitudeLeft',
		PageUp: 'zoomIn',
		PageDown: 'zoomOut',
		'+': 'zoomIn',
		'-': 'zoomOut',
		' ': 'toggleAutorotate',
	},
};

export default settings;
