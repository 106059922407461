import {useRef} from '@wordpress/element';
import {useSelector, useDispatch} from 'react-redux';
import SCSS from './Floorplan.module.scss';
import {
	selectCurrent,
	selectSCENES,
	selectCompass,
	updateClicked,
	fetchPanorama,
} from '../../../store/panoramaSlice';
import IScene from '../../../scenes/IScene';
import Hotspot from './Hotspot';

const _ = require('lodash');
// const classnames = require('classnames');

const Floorplan = () => {
	const dispatch = useDispatch();
	const plan = useRef(null);
	const current: IScene = useSelector(selectCurrent);
	const currentLevel = current.level;
	const SCENES: Array<IScene> = useSelector(selectSCENES);
	const compass = useSelector(selectCompass);
	const LevelScenes: Array<IScene> = _.filter(SCENES, {level: currentLevel});

	const changeScene = (scene: string): void => {
		dispatch(updateClicked('miniplan'));
		dispatch(fetchPanorama(scene));
	};
	// const leftClasses = [
	// 	SCSS.ArrowLeft,
	// 	{
	// 		[`${SCSS.Disable}`]: currentLevel === 'basement',
	// 	},
	// ];

	// const rightClasses = [
	// 	SCSS.ArrowRight,
	// 	{
	// 		[`${SCSS.Disable}`]: currentLevel === 'upper',
	// 	},
	// ];

	// const LeftArrowAction = () => {
	// 	const goto = currentLevel === 'upper' ? 'Main Hallway' : 'Basement Entry';
	// 	dispatch(fetchPanorama(goto));
	// };

	// const RightArrowAction = () => {
	// 	const goto =
	// 		currentLevel === 'basement' ? 'Main Hallway' : 'Upper Hallway 1';
	// 	dispatch(fetchPanorama(goto));
	// };

	return (
		<>
			<div ref={plan} className={SCSS.Floorplan}>
				<img src={current.keyplate} alt={current.name} />
				{LevelScenes.map((s: IScene, index: number) => (
					<Hotspot
						changeScene={changeScene}
						name={s.name}
						active={s.name === current.name}
						degree={compass.degree}
						key={index}
						x={s.hotspot.x}
						y={s.hotspot.y}
					/>
				))}
			</div>
			{/*<div className={SCSS.LevelButtonContainer}>*/}
			{/*	<div className={classnames(leftClasses)} onClick={LeftArrowAction}>*/}
			{/*		<span />*/}
			{/*	</div>*/}
			{/*	<div className={SCSS.Label}>{currentLevel}</div>*/}
			{/*	<div className={classnames(rightClasses)} onClick={RightArrowAction}>*/}
			{/*		<span />*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default Floorplan;
