import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Ensuite: IScene = {
	name: 'Ensuite',
	pan: '180deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 128,
		y: 315,
	},
	images: Renderings.__ensuite__,
	default: {
		latitude: 0.017515269885598705,
		longitude: 2.7118716553569064,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7388974552746284,
				longitude: 6.258640633529953,
			},
			target: {
				name: 'Master Bedroom 1',
				view: {
					latitude: 0.008233019760569338,
					longitude: 0.5099141237733215,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.40510935364472744,
				longitude: 0.007107977506063326,
			},
			target: {
				name: 'Master Bedroom 2',
				view: {
					latitude: -0.07977456634622548,
					longitude: 0.11698372113992772,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.29049266081873215,
				longitude: 0.037871478131791377,
			},
			target: {
				name: 'Terrace',
				view: {
					latitude: 0.12809907910434415,
					longitude: 0.14487159954049486,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5138946289868365,
				longitude: 5.197443263216723,
			},
			target: {
				name: 'Living',
				view: {
					latitude: 0.008402535743134543,
					longitude: 5.365489560815731,
				},
			},
		},
	],
};

export default __Ensuite;
