import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/keyplate.svg';

const __Master_Bedroom_1: IScene = {
	name: 'Master Bedroom 1',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 128,
		y: 267,
	},
	images: Renderings.__master_bedroom_1__,
	default: {
		latitude: 0.008233019760569338,
		longitude: 0.5099141237733215,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.49972168329999,
				longitude: 6.274588101026207,
			},
			target: {
				name: 'Master Bedroom 2',
				view: {
					latitude: -0.045634259293784396,
					longitude: 3.126359154921573,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1900186050791417,
				longitude: 0.04232010840670422,
			},
			target: {
				name: 'Terrace',
				view: {
					latitude: -0.00332005802498081,
					longitude: 0.5695496957869238,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.700287753210032,
				longitude: 3.2065364275371877,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: 0.056992955347380914,
					longitude: 3.1402869157628652,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6644886638172789,
				longitude: 4.174635556352851,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.009340965359313724,
					longitude: 3.620668915930655,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5099911281835596,
				longitude: 3.638764218403805,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.009340965359313724,
					longitude: 3.620668915930655,
				},
			},
		},
	],
};

export default __Master_Bedroom_1;
