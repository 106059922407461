import {useRef, useState} from '@wordpress/element';
import {useClickAway} from 'react-use';
import {useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SCSS from './RoomSelection.module.scss';
import IScene from '../../../../scenes/IScene';
import {useAppDispatch} from '../../../../store';
import {
	selectSCENES,
	fetchPanorama,
	closePOP,
	updateClicked,
} from '../../../../store/panoramaSlice';

const _ = require('lodash');

type Props = {
	close: () => void;
};

const RoomSelection = ({close}: Props) => {
	const ref = useRef(null);
	useClickAway(ref, () => {
		close();
	});
	const dispatch = useAppDispatch();
	const SCENES = useSelector(selectSCENES);
	const MAIN = _.filter(SCENES, {level: 'main'});
	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};
	const change = (scene: string) => {
		dispatch(updateClicked('menu'));
		dispatch(fetchPanorama(scene));
		dispatch(closePOP());
	};

	const MainButtons = MAIN.map((btn: IScene, index: number) => (
		<Button onClick={() => change(btn.name)} key={index}>
			{_.replace(btn.name, /Main/g, '')}
		</Button>
	));

	return (
		<div className={SCSS.RoomSelection} ref={ref}>
			<ButtonGroup
				variant='contained'
				orientation='vertical'
				disableElevation={true}
				fullWidth
				size='small'
			>
				{MainButtons}
			</ButtonGroup>
		</div>
	);
};

export default RoomSelection;
